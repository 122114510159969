import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermCareInsuranceCost = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    
  return (
  <PageLayout>
    <SEO
      title="Long Term Care Insurance Cost of Care Survey | LTCPG"
      ogDescription="The Genworth Long Term Care Insurance Cost of Care Survey is an invaluable tool that will show you the cost of care in your area years into the future."
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h1>
            Long Term Care Insurance Cost
          </h1>
          <p><a href="https://www.genworth.com/about-us.html" target="_blank">Genworth</a> is a Fortune 500 insurance holding company who offers long term care insurance and other financial protection products. Genworth is among the 18 traditional long term care providers including Mass Mutual, Mutual of Omaha, Transamerica and John Hancock to name a few. At The Long Term Care Planning Group, we are able to work with all long term care insurance providers to optimize your long term care planning.</p>

          <p><a href="https://www.genworth.com/aging-and-you/finances/cost-of-care.html" target="_blank">The Genworth Cost of Long Term Care Survey Tool</a> helps estimate median costs in major cities and all 50 states.</p>
          <RegisterForWebinarButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_364297305.jpg"
            alt="Long Term Care Insurance Cost of Care Survey"
          />
          <p>This tool is available to everyone. Let’s walk through an example about how you might consider using this tool for your own long term care insurance cost planning purposes and our follow up discussion with you.</p>
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_187497612.jpg"
          alt="Long Term Care Insurance Cost of Care Survey Walk Through"
        className="right-point"
        />
      }
      imageGrow
      textPadded
      text={
        <div>
          <h2>Long Term Care Insurance Cost Walk-through</h2>
          <p>When you go to the <a href="https://www.genworth.com/aging-and-you/finances/cost-of-care.html" target="_blank">Cost of Long Term Care Survey Tool</a>, there's an interactive map.  Genworth studies the long term care insurance cost for 10 to 12 cities and every state in the United States. When you can enter a city state or zip code, for example let’s enter in Atlanta, it shows the cost areas they study. There's a component of the website which displays the monthly median costs.</p>

          <p>In this Atlanta example for 2019, you see homemaker services are about $4,000 a month. Adult daycare can be $1,500 a month and a semi-private room in a nursing home is $7,756 a month. And a private room is more expensive at $8,669. You can look at costs on an hourly, daily, monthly and annual rates.</p>
          <ScheduleVisitButton />
        </div>
      }
    />
    <PageWrapper maxWidth color="green">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>States Have Different Long Term Care Insurance Cost</h2>
            <p>It can help you understand the long term care insurance cost anywhere in the United States. This is important since some people will decide to move to a different location to be closer to family or because they plan to retire somewhere other than where they are currently living. To help with the location decision, you can compare two areas. Understanding the various costs based on geographic locations helps you put long term care premiums into its proper perspective.</p>

            <p>Another important use of this tool is to calculate future long term care insurance costs based on Genworth’s long historical data collection and economic predications. Using the Atlanta example again, move the marker on the timeline from 2019 to 2029 for a 10 year to see the forecasted median long term care insurance costs. No surprise, you will see the long term care insurance cost increase. You can even change the rate of inflation if you don’t agree with their preset rate.</p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/AdobeStock_13033979.jpg"
              alt="Long Term Care Insurance Cost of Care Survey - Webinar"
            />
            <p>Hopefully, this long term care issue and the associated challenges never present themselves to you or a loved one. But if it does, you must be clear on what the long term care insurance costs are now and what they could be five, 10, 20 years down the line.</p>
            <RegisterForWebinarButton />
          </div>
        }
      />
    </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
      <div>
        <img
          src="/images/AdobeStock_118376572.jpg"
          alt="Long Term Care Insurance Cost of Care Survey - Consultation"
        />
        <Blockquote>Give us a call to ask your individual questions. We’ll confidentially discuss your long term health care planning needs or those of a loved one, <a href="tel:6788145088">(678) 814-5088</a>. This is a complimentary, no-obligation conversation.</Blockquote>
      </div>
        }
        textRight={
          <div>
            <h2>Long Term Care Insurance</h2>

            <p>If you purchase <a href="/long-term-care-insurance-companies/">long term care insurance</a>, it will provide a stream of income to cover care related expenses in the location and setting of your choosing. If you don't purchase long term care, people end up using funds from their 401k, their IRAs and the equity of their house to defray these costs. Once their finances are depleted to certain minimum income and asset level as set forth by the state's <a href="/government-does-not-pay-for-long-term-care/">Medicaid system</a>, long term care expenses are paid for by the individual. To have the most options and get the best premium choices, we highly recommend you do your long term care planning and purchase the insurance when you're younger than 50 and most likely healthier than if you wait until you’re older.</p>
          <ScheduleVisitButton />
          </div>
        }
      />
    <QuotePanel text="Hello World" person="John Doe" />
  </PageLayout>
  )
}

export default LongTermCareInsuranceCost
